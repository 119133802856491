import React, { useContext, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import Confetti from 'react-dom-confetti'
import UserContext from '../context/user/userContext'
import Meta from '../components/Meta'
import LetterKeys from '../components/LetterKeys'
import { getWord, matchLetters, getVoices } from '../actions/wordGuessActions'
import { earnCoins } from '../actions/pokeguessActions'
import {
  Button,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Message,
} from 'semantic-ui-react'

const GuessWordPage = ({ history }) => {
  const userContext = useContext(UserContext)
  const { userInfo } = userContext

  const queryClient = useQueryClient()

  const ASSETS_URL = 'https://assets.meaah.com/file/meaah-poke/lib'

  const startMsg = `Guess the whole word correctly to win 25 coins. Good luck, ${userInfo.nickname}!`
  const [gameMsg, setGameMsg] = useState(startMsg)
  const [reset, setReset] = useState(true)
  const [gameActive, setGameActive] = useState(true)
  const [winGame, setWinGame] = useState(false)
  const [loseGame, setLoseGame] = useState(false)
  const [word, setWord] = useState('')
  const [guessesLeft, setGuessesLeft] = useState(8)
  const [correctLetters, setCorrectLetters] = useState([])
  const [guessedLetters, setGuessedLetters] = useState([])
  const [currentGuess, setCurrentGuess] = useState('')
  const [wrongLetters, setWrongLetters] = useState([])
  const [definition, setDefinition] = useState([])
  const [audioUrl, setAudioUrl] = useState('')
  const [repeatAlert, setRepeatAlert] = useState(false)

  const ding = new Audio(`${ASSETS_URL}/sounds/ding.mp3`)
  const wrongSound = new Audio(`${ASSETS_URL}/sounds/wrongAns.mp3`)
  const winSound = new Audio(`${ASSETS_URL}/sounds/amazing.mp3`)
  const loseSound = new Audio(`${ASSETS_URL}/sounds/failSound.mp3`)
  const confettiEffect = new Audio(`${ASSETS_URL}/sounds/winnerSound.mp3`)

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else if (reset) {
      newGame()
      setReset(false)
    }
    // eslint-disable-next-line
  }, [history, userInfo, reset, guessedLetters])

  const updateCoins = useMutation(earnCoins)

  useEffect(() => {
    let win = matchLetters(correctLetters, guessedLetters)
    if (win && guessedLetters.length > 2) {
      const timeout = setTimeout(() => {
        winSound.play()
        confettiEffect.play()
        setGameMsg(`Good job, ${userInfo.nickname}! You won 25 coins!`)
        setWinGame(true)
        setGameActive(false)
        updateCoins.mutate(
          { coins: 25 },
          {
            onSettled: () => {
              queryClient.invalidateQueries('bag')
            },
          }
        )
      }, 300)

      return () => clearTimeout(timeout)
    }
    // eslint-disable-next-line
  }, [guessedLetters])

  const newGame = async () => {
    synth.cancel()
    setWinGame(false)
    setLoseGame(false)
    setGameActive(true)
    setRepeatAlert(false)
    setCorrectLetters([])
    setGuessedLetters([])
    setWrongLetters([])
    setGuessesLeft(8)
    setGameMsg(startMsg)
    const data = await getWord()
    const { word, definition, audio } = data
    let ans = word.toLowerCase()
    setWord(ans)
    let splitWord = ans.split('')
    let def = definition.length < 1 ? definition : definition.slice(0, 1)
    setCorrectLetters(splitWord)
    setDefinition(def)
    setAudioUrl(audio)
  }

  const handleReset = () => setReset(true)

  let audio = new Audio(audioUrl)
  const handleAudio = () => audio.play()

  const evaluateGuess = async (value) => {
    if (gameActive) {
      if (guessedLetters.includes(value)) {
        setCurrentGuess(value)
        setRepeatAlert(true)
      } else {
        setRepeatAlert(false)
        setGuessedLetters((guessedLetters) => [...guessedLetters, value])
        // If wrong guess...
        if (!correctLetters.includes(value)) {
          if (guessesLeft === 1) {
            setGuessesLeft((guessesLeft) => guessesLeft - 1)
            setWrongLetters((wrongLetters) => [...wrongLetters, value])
            setGameMsg(
              `Oh no, you have run out of guesses! Better luck next time, ${userInfo.nickname}.`
            )
            loseSound.play()
            setGameActive(false)
            setLoseGame(true)
          } else if (guessesLeft !== 0) {
            wrongSound.play()
            setGuessesLeft((guessesLeft) => guessesLeft - 1)
            setWrongLetters((wrongLetters) => [...wrongLetters, value])
          }
        } else {
          // correct guess...
          ding.play()
        }
      }
    }
  }

  const onKeyup = (e) => {
    let key = e.key
    let regex = /[A-Za-z]/
    let validInput = regex.test(key)
    let value = key.toLowerCase()

    if (validInput && (value === 'enter' || value.length === 1)) {
      if (!gameActive) {
        if (value === 'enter') {
          setReset(true)
        }
      } else {
        if (value.length === 1) {
          evaluateGuess(value)
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', onKeyup)
    return () => window.removeEventListener('keyup', onKeyup)
    // eslint-disable-next-line
  }, [correctLetters, guessedLetters, guessesLeft, gameActive])

  const handleLetterClick = (e) => {
    let value = e.target.value
    evaluateGuess(value)
  }
  let synth = window.speechSynthesis
  const speakDef = () => {
    let voice
    voice = getVoices()
    let utterThis = new SpeechSynthesisUtterance(definition)
    utterThis.voice = voice.voice
    utterThis.rate = 0.8
    synth.speak(utterThis)
  }

  const handleTTS = () => {
    if (gameActive) {
      if ('speechSynthesis' in window) {
        speakDef()
      } else {
        window.alert('Text-to-speech not supported by your browser')
      }
    }
  }

  return (
    <Grid container centered id='wordguess-page-grid-container'>
      <Meta
        title='Meaah | Gengar Guess Word'
        description='Guess the mystery word'
      />

      <Grid.Row id='wordguess-title-row'>
        <Image
          src={`${ASSETS_URL}/images/wordguess/wordguess-title.png`}
          className='wordguess-title-image'
        />
        <Image
          src={`${ASSETS_URL}/images/wordguess/gengargrin.gif`}
          className='wordguess-title-image'
        />
      </Grid.Row>

      <Grid.Row id='wordguess-middle-container'>
        <Grid.Column width={11}>
          <Message id='game-main-msg' size='large' color='violet'>
            {gameMsg}{' '}
            {!gameActive && (
              <Button
                id='small-reset-btn'
                icon
                compact
                floated='right'
                color='violet'
                size='small'
                style={{ paddingBottom: 5 }}
                onClick={handleReset}
              >
                <Icon name='repeat' />
              </Button>
            )}
          </Message>

          <Grid
            centered
            textAlign='center'
            columns={10}
            id='split-word-container'
          >
            <Grid.Row>
              {!gameActive && (
                <div className='answer-msg'>
                  The correct answer is '
                  {<span style={{ color: 'red' }}>{word}</span>}
                  '.{' '}
                  <Button
                    size='mini'
                    color='teal'
                    icon='volume up'
                    style={{ marginLeft: 8 }}
                    onClick={handleAudio}
                  />
                  {loseGame && (
                    <Image
                      className='losegame-img'
                      src={`${ASSETS_URL}/images/wordguess/gengarwins.gif`}
                      alt='Gengar laughing'
                    />
                  )}
                  {winGame && (
                    <Image
                      className='wingame-img'
                      src={`${ASSETS_URL}/images/wordguess/ohyeahpikachu.gif`}
                      alt='Pikachu thumbs up'
                    />
                  )}
                </div>
              )}
            </Grid.Row>
            <Grid.Row>
              {correctLetters.map((letter, i) => (
                <Grid.Column className='wordguess-letters' key={i}>
                  {guessedLetters.includes(letter) ? letter : '__'}
                </Grid.Column>
              ))}
            </Grid.Row>
            {repeatAlert && (
              <Message
                id='repeat-alert'
                negative
                compact
                content={`You have already picked the letter '${currentGuess}' before.  Choose another one.`}
              />
            )}
          </Grid>
        </Grid.Column>

        <Grid.Column id='wordguess-right-column' width={5}>
          <Grid.Row>
            <Header id='wordguess-definition' as='h3' color='orange'>
              <Button
                onClick={handleTTS}
                color='orange'
                icon='volume up'
                style={{ padding: 1 }}
              />
              This word means:
            </Header>

            <List style={{ marginLeft: 0 }}>
              {definition.map((definition) => (
                <List.Item
                  key={definition}
                  content={definition}
                  className='wordguess-definition'
                />
              ))}
            </List>

            <Image
              src={`${ASSETS_URL}/images/wordguess/mw-logo.png`}
              alt='merriam-webster logo'
              avatar
            />
            <span style={{ fontSize: 9, color: 'grey' }}>
              Powered by Merriam-Webster Inc.
            </span>
          </Grid.Row>
          <Grid.Row style={{ height: 35 }}>
            <Header id='guesses-left' as='h2' style={{ marginTop: 15 }}>
              Guesses left: <span style={{ color: 'red' }}>{guessesLeft}</span>
            </Header>
          </Grid.Row>
          <Grid.Row id='wrongguess-container'>
            <Header id='wrongguess-header' as='h3' color='violet'>
              Wrong guesses:
            </Header>
            <Grid.Row>
              {wrongLetters.map((letter, i) => (
                <button key={i} className='wrong-letter'>
                  {letter}
                </button>
              ))}
            </Grid.Row>
          </Grid.Row>
          <Button
            fluid
            size='small'
            color='violet'
            icon
            labelPosition='right'
            onClick={handleReset}
          >
            Guess another word
            <Icon name='repeat' />
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Confetti active={winGame} />
      <LetterKeys parentMethod={handleLetterClick} />
    </Grid>
  )
}

export default GuessWordPage
