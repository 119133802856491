import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Button, Grid, Header, Image, Reveal } from 'semantic-ui-react'
import Copyright from '../components/Copyright'
import UserContext from '../context/user/userContext'
import Meta from '../components/Meta'

const HomePage = () => {
  const userContext = useContext(UserContext)
  const { userInfo } = userContext

  const ASSETS_URL = 'https://assets.meaah.com/file/meaah-poke/lib'

  const redirect = new Audio(`${ASSETS_URL}/sounds/whooshSound.mp3`)

  return (
    <Grid
      container
      columns={1}
      verticalAlign='middle'
      centered
      className='homepage-container'
    >
      {!userInfo && <Meta color='#C2C2C2' />}
      <Grid.Row>
        <Image
          id='home-title'
          src={
            !userInfo
              ? `${ASSETS_URL}/images/home/home-title-white.png`
              : `${ASSETS_URL}/images/home/home-title-color.png`
          }
        />

        <Grid.Row columns={1}>
          <Header textAlign='center' as='h1' style={{ color: '#356BBC' }}>
            {!userInfo ? 'Sign in to play!' : "Let's play!"}
            <Image
              verticalAlign='bottom'
              spaced='left'
              size='large'
              src={`${ASSETS_URL}/images/pokemons/walkingPikachu.gif`}
            />
          </Header>
        </Grid.Row>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          className={!userInfo ? 'game-container' : 'game-container-signed-in'}
          width={7}
          textAlign='center'
        >
          <Reveal animated='move up'>
            <Reveal.Content visible>
              <Image
                className='game-img'
                rounded
                src={`${ASSETS_URL}/images/home/pokeguess-home.png`}
              />
            </Reveal.Content>
            <Reveal.Content
              className={
                !userInfo
                  ? 'instructions-container'
                  : 'instructions-container-signed-in'
              }
              hidden
            >
              <div>
                <h3 className='instructions-title'>How to Play</h3>
                <ul className='instructions'>
                  <li>Guess the pokemon name correctly to earn coins</li>
                  <li>
                    There are 2 modes with different rewards: Picture mode and
                    Shadow mode
                  </li>
                  <li>Picture mode reward: 5 coins</li>
                  <li>Shadow mode reward: 10 coins</li>
                </ul>
              </div>

              <Link to='/pokeguess'>
                <Button onClick={() => redirect.play()} color='green'>
                  Play now!
                </Button>
              </Link>
            </Reveal.Content>
          </Reveal>
        </Grid.Column>
        <Grid.Column width={1}></Grid.Column>
        <Grid.Column
          className={!userInfo ? 'game-container' : 'game-container-signed-in'}
          width={7}
          textAlign='center'
        >
          <Reveal animated='move right'>
            <Reveal.Content visible>
              <Image
                rounded
                src={`${ASSETS_URL}/images/home/wordguess-home.png`}
              />
            </Reveal.Content>
            <Reveal.Content
              className={
                !userInfo
                  ? 'instructions-container'
                  : 'instructions-container-signed-in'
              }
              hidden
            >
              <div>
                <h3 className='instructions-title'>How to Play</h3>
                <ul className='instructions'>
                  <li>Gengar will choose a word for you to guess</li>
                  <li>You will be given the word's meaning as a hint</li>
                  <li>Guess by clicking on any letter</li>
                  <li>You have 8 guesses in total</li>
                  <li>Earn 25 coins if you guess the whole word correctly!</li>
                </ul>
              </div>
              <Link to='/wordguess'>
                <Button onClick={() => redirect.play()} color='green'>
                  Play now!
                </Button>
              </Link>
            </Reveal.Content>
          </Reveal>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Copyright />
      </Grid.Row>
    </Grid>
  )
}

export default HomePage
