import React from 'react'
import '../lib/style/profileAnimation.scss'

const PokeFriendsPic = () => {
  return (
    <div>
      <div className='pic-container'>
        <ul className='moon pic-ul'>
          <li className='crater pic-li'></li>
          <li className='crater pic-li'></li>
          <li className='crater pic-li'></li>
        </ul>
        <ul className='mountain-range pic-ul'>
          <li className='mountain pic-li'></li>
          <li className='mountain pic-li'></li>
          <li className='mountain pic-li'></li>
          <li className='mountain pic-li'></li>
          <li className='mountain pic-li'></li>
          <li className='mountain pic-li'></li>
          <li className='mountain pic-li'></li>
          <li className='mountain pic-li'></li>
          <li className='mountain pic-li'></li>
          <li className='mountain pic-li'></li>
          <li className='mountain pic-li'></li>
        </ul>
        <ul className='forest pic-ul'>
          <li className='hill pic-li'></li>
          <li className='hill pic-li'></li>
          <li className='hill pic-li'></li>
        </ul>
        <ul className='sparkles pic-ul'>
          <li className='sparkle pic-one pic-li'></li>
          <li className='sparkle pic-one pic-li'></li>
          <li className='sparkle pic-one pic-li'></li>
          <li className='sparkle pic-one pic-li'></li>
          <li className='sparkle pic-two pic-li'></li>
          <li className='sparkle pic-two pic-li'></li>
          <li className='sparkle pic-two pic-li'></li>
          <li className='sparkle pic-two pic-li'></li>
          <li className='sparkle pic-three pic-li'></li>
          <li className='sparkle pic-three pic-li'></li>
          <li className='sparkle pic-three pic-li'></li>
          <li className='sparkle pic-three pic-li'></li>
          <li className='sparkle pic-four pic-li'></li>
          <li className='sparkle pic-four pic-li'></li>
          <li className='sparkle pic-four pic-li'></li>
          <li className='sparkle pic-four pic-li'></li>
          <li className='sparkle pic-five pic-li'></li>
          <li className='sparkle pic-five pic-li'></li>
          <li className='sparkle pic-five pic-li'></li>
          <li className='sparkle pic-five pic-li'></li>
          <li className='sparkle pic-six pic-li'></li>
          <li className='sparkle pic-six pic-li'></li>
          <li className='sparkle pic-six pic-li'></li>
          <li className='sparkle pic-six pic-li'></li>
          <li className='sparkle pic-seven pic-li'></li>
          <li className='sparkle pic-seven pic-li'></li>
          <li className='sparkle pic-seven pic-li'></li>
          <li className='sparkle pic-seven pic-li'></li>
          <li className='sparkle pic-eight pic-li'></li>
          <li className='sparkle pic-eight pic-li'></li>
          <li className='sparkle pic-eight pic-li'></li>
          <li className='sparkle pic-eight pic-li'></li>
        </ul>
        <div className='grass'>
          <div className='pokemon'>
            <div className='poke' id='bulbasaur'>
              <div className='ear'></div>
              <div className='ear'></div>
              <div className='head'></div>
              <div className='leg'></div>
              <div className='bulba-body'></div>
              <div className='bulbs'>
                <div className='bulb'></div>
              </div>
            </div>
            <div className='poke' id='pikachu'>
              <div className='ear'></div>
              <div className='ear'></div>
              <div className='hand'></div>
              <div className='pika-body'></div>
              <div className='head'></div>
              <div className='pika-tail'></div>
            </div>
          </div>
        </div>
      </div>
      <div className='pokefriends-copyright'>
        Copyright &copy; 2021 by Elisabeth Diang. <br />
        Click
        <a
          href='https://codepen.io/elisabethdiang/pen/YzzzYxj'
          target='_blank'
          rel='noreferrer nofollow'
        >
          {' '}
          here
        </a>{' '}
        for codepen and related usage copyright and license notices.
      </div>
    </div>
  )
}

export default PokeFriendsPic
