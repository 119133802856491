import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Button, Container, Icon, Image, Label, Menu } from 'semantic-ui-react'
import UserContext from '../context/user/userContext'
import Score from '../components/Score'

const Header = () => {
  const userContext = useContext(UserContext)
  const { userInfo, logout } = userContext

  const ASSETS_URL = 'https://assets.meaah.com/file/meaah-poke/lib'

  const handleLogout = () => {
    logout()
  }

  return (
    <header>
      <Container style={{ width: '100vw', minWidth: 740 }}>
        {userInfo ? (
          <Menu
            id='header-container'
            className='ui top fixed menu'
            borderless
            size='tiny'
            icon='labeled'
            color='blue'
            inverted
          >
            <Link to='/'>
              <Menu.Item className='header-icons' name='home'>
                <Icon className='meaah' />
                Games
              </Menu.Item>
            </Link>

            <Link to='/profile'>
              <Menu.Item
                className='header-icons'
                name='profile'
                style={{ minWidth: 60 }}
              >
                <Icon className='profile-icon' />
                Profile
              </Menu.Item>
            </Link>

            <Link to='/pokemons'>
              <Menu.Item
                className='header-icons'
                name='pokemons'
                style={{ minWidth: 60 }}
              >
                <Icon className='pokemons' />
                Pokemons
              </Menu.Item>
            </Link>
            <Link to='/eggs'>
              <Menu.Item
                className='header-icons'
                name='egg'
                style={{ minWidth: 60 }}
              >
                <Icon className='egg' />
                Eggs
              </Menu.Item>
            </Link>

            <Menu.Menu position='right'>
              <Score />

              <Label id='header-greeting' size='large' color='blue' image>
                <Image
                  className='header-greeting-logo'
                  src={`${ASSETS_URL}/images/header/location.png`}
                  alt='location icon'
                />{' '}
                <span
                  style={{ color: 'gold' }}
                >{`Hello, ${userInfo.nickname}.`}</span>
              </Label>

              <Button
                animated='fade'
                primary
                size='tiny'
                onClick={handleLogout}
              >
                <Button.Content hidden>
                  <Icon name='sign-out' />
                </Button.Content>
                <Button.Content visible>Logout</Button.Content>
              </Button>
            </Menu.Menu>
          </Menu>
        ) : (
          <Menu
            id='header-container'
            className='ui top fixed menu'
            borderless
            size='tiny'
            icon='labeled'
            style={{ backgroundColor: 'black' }}
          >
            <Link to='/'>
              <Image
                className='header-meaah-logo'
                src={`${ASSETS_URL}/images/header/meaahicon.png`}
                alt='meaah logo'
              />
            </Link>

            <Link to='/'>
              <Menu.Item
                className='header-icons'
                name='home'
                style={{ color: 'white' }}
              >
                <Icon name='home' />
                Home
              </Menu.Item>
            </Link>
            <Image
              id='header-pikachu-gif'
              src={`${ASSETS_URL}/images/header/pikachu-appear.gif`}
              alt='pikachu animation'
            />

            <Menu.Menu position='right'>
              <Menu.Item>
                <Button.Group id='header-buttons'>
                  <Link to='/login'>
                    <Button content='Sign in' color='yellow' />
                  </Link>
                  <Button.Or />
                  <Link to='/register'>
                    <Button content='Register' color='blue' />
                  </Link>
                </Button.Group>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        )}
      </Container>
    </header>
  )
}

export default Header
