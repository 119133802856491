import React, { useContext, useState, useEffect } from 'react'
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react'
import UserContext from '../context/user/userContext'
import PokeFriendsPic from '../components/PokeFriendsPic'
import Meta from '../components/Meta'

const ProfilePage = ({ history }) => {
  const userContext = useContext(UserContext)
  const {
    loading,
    error,
    userInfo,
    updateSuccessMsg,
    updateUserProfile,
    clearUpdateSuccessMsg,
    clearError,
  } = userContext

  const ASSETS_URL = 'https://assets.meaah.com/file/meaah-poke/lib'

  const [nickname, setNickname] = useState(userInfo.nickname)
  const [username, setUsername] = useState(userInfo.username)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  useEffect(() => {
    clearError()
    clearUpdateSuccessMsg()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else if (!error) {
      setNickname(userInfo.nickname)
      setUsername(userInfo.username)
    }
  }, [history, error, userInfo])

  const submitHandler = (e) => {
    e.preventDefault()
    setMessage(null)
    clearError()
    clearUpdateSuccessMsg()
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else if (username.length < 5) {
      setMessage('Username must be at least 5 characters long')
    } else if (password !== '' && password.length < 5) {
      setMessage('Password must be at least 5 characters long')
    } else {
      updateUserProfile({
        _id: userInfo._id,
        nickname,
        username,
        password,
      })
    }
  }

  return (
    <Grid
      id='profile-page-container'
      container
      columns={2}
      textAlign='center'
      verticalAlign='middle'
    >
      <Meta
        title='Meaah | Profile'
        description='View and/or update user profile'
      />
      <Grid.Column id='pic-column' width={8} only='tablet computer'>
        <PokeFriendsPic />
      </Grid.Column>
      <Grid.Column id='form-column' width={8}>
        <Header id='profile-header' as='h2' textAlign='center'>
          <Image
            id='profile-logo'
            src={`${ASSETS_URL}/images/profile/pikachu-whole.png`}
          />
          My Profile
        </Header>
        {message && <Message attached negative content={message} />}
        {error && <Message attached negative content={error} />}
        {updateSuccessMsg && (
          <Message attached positive content={updateSuccessMsg} />
        )}
        <Form size='large'>
          <Segment textAlign='left'>
            <Form.Input
              fluid
              label='Nickname'
              icon='user'
              iconPosition='left'
              placeholder='Your nickname for profile display'
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
            />
            <Form.Input
              fluid
              label='Username'
              icon='user'
              iconPosition='left'
              placeholder='Account username (at least 5 characters long)'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Form.Input
              fluid
              autoComplete='off'
              label='Password'
              icon='lock'
              iconPosition='left'
              placeholder='New password (at least 5 characters long)'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Input
              fluid
              autoComplete='off'
              label='Confirm password'
              icon='lock'
              iconPosition='left'
              placeholder='This must match the password above'
              type='password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              onClick={submitHandler}
              disabled={loading}
              color='green'
              fluid
              size='large'
            >
              Update
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  )
}

export default ProfilePage
