import React, { useReducer } from 'react'
import axios from 'axios'
import UserContext from './userContext'
import UserReducer from './userReducer'
import {
  CLEAR_ERROR,
  CLEAR_UPDATE_SUCCESS_MSG,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from '../types'

const API_URL = 'https://pokegame-backend.vercel.app'

const UserState = (props) => {
  const userInfoFromStorage = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

  const initialState = {
    userInfo: userInfoFromStorage,
    updateSuccessMsg: '',
    loading: false,
    error: '',
  }

  const [state, dispatch] = useReducer(UserReducer, initialState)

  const login = async (username, password) => {
    try {
      dispatch({
        type: LOGIN_REQUEST,
      })

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${API_URL}/api/user/login`,
        { username, password },
        config
      )

      dispatch({
        type: LOGIN_SUCCESS,
        payload: data,
      })

      localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
      dispatch({
        type: LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

  const logout = () => {
    localStorage.removeItem('userInfo')
    dispatch({ type: LOGOUT })
    document.location.href = '/login'
  }

  const register = async (nickname, username, password) => {
    try {
      dispatch({
        type: REGISTER_REQUEST,
      })

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

      const { data } = await axios.post(
        `${API_URL}/api/user`,
        { nickname, username, password },
        config
      )

      dispatch({
        type: REGISTER_SUCCESS,
        payload: data,
      })

      dispatch({
        type: LOGIN_SUCCESS,
        payload: data,
      })

      localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
      dispatch({
        type: REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

  const updateUserProfile = async (user) => {
    try {
      dispatch({
        type: UPDATE_USER_REQUEST,
      })

      const { userInfo } = state
      const token = userInfo.token

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }

      const { data } = await axios.put(
        `${API_URL}/api/user/profile`,
        user,
        config
      )

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: data,
      })
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data,
      })
      localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message

      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: UPDATE_USER_FAIL,
        payload: message,
      })
    }
  }

  const clearUpdateSuccessMsg = () => {
    dispatch({ type: CLEAR_UPDATE_SUCCESS_MSG })
  }

  const clearError = () => {
    dispatch({ type: CLEAR_ERROR })
  }

  return (
    <UserContext.Provider
      value={{
        userInfo: state.userInfo,
        updateSuccessMsg: state.updateSuccessMsg,
        loading: state.loading,
        error: state.error,
        login,
        logout,
        register,
        updateUserProfile,
        clearUpdateSuccessMsg,
        clearError,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export default UserState
