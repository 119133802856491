import axios from 'axios'
import { useQuery } from 'react-query'

const API_URL = 'https://pokegame-backend.vercel.app'

const fetchBag = async () => {
  const info = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

  const { token } = info

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios
    .get(`${API_URL}/api/user/mybag`, config)
    .then((res) => res.data)

  return response
}

const GetBagItems = () => {
  return useQuery('bag', fetchBag)
}

export default GetBagItems
