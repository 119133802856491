import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react'
import Meta from '../components/Meta'
import UserContext from '../context/user/userContext'

const LoginPage = ({ history, location }) => {
  const userContext = useContext(UserContext)
  const { clearError, loading, error, userInfo, login } = userContext

  const ASSETS_URL = 'https://assets.meaah.com/file/meaah-poke/lib'

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const sound = new Audio(`${ASSETS_URL}/sounds/loginSound.mp3`)

  useEffect(() => {
    clearError()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (userInfo) {
      sound.play()
      if (location.state && location.state.from) {
        history.replace(location.state.from)
      } else {
        history.push('/')
      }
    }
    // eslint-disable-next-line
  }, [history, userInfo, location])

  const submitHandler = (e) => {
    e.preventDefault()

    login(username, password)

    setUsername('')
    setPassword('')
  }

  return (
    <Grid id='login-container' textAlign='center' verticalAlign='middle'>
      <Meta title='Meaah | Sign in' description='Meaah user sign in page' />
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as='h2' textAlign='center'>
          <Image src={`${ASSETS_URL}/images/login/compass.png`} /> Sign in to
          account
        </Header>
        {error && <Message attached negative content={error} />}
        <Form size='large'>
          <Segment textAlign='left'>
            <Form.Input
              fluid
              autoComplete='off'
              required
              label='Username'
              icon='user'
              iconPosition='left'
              placeholder='Username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Form.Input
              fluid
              autoComplete='off'
              required
              label='Password'
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              onClick={submitHandler}
              disabled={loading}
              color='red'
              fluid
              size='large'
            >
              Sign in
            </Button>
          </Segment>
        </Form>
        <Message attached='bottom' color='yellow'>
          Don't have an account yet? <Link to='/register'> Register here!</Link>
        </Message>
      </Grid.Column>
    </Grid>
  )
}

export default LoginPage
