import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import UserContext from '../context/user/userContext'

const AppRoutes = ({ component: Component, path, isPrivate, ...rest }) => {
  const userContext = useContext(UserContext)
  const { userInfo } = userContext

  return (
    <Route
      path={path}
      render={(props) =>
        isPrivate && !Boolean(userInfo) ? (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        ) : (
          <Component {...props} />
        )
      }
      {...rest}
    />
  )
}

export default AppRoutes
