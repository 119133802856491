import React from 'react'
import { Container } from 'semantic-ui-react'

const Copyright = () => {
  let today = new Date()
  let currentYear = today.getFullYear()
  let year = currentYear === 2021 ? '2021' : '2021 - ' + currentYear

  return (
    <footer>
      <Container id='copyright' textAlign='justified'>
        <p>
          All content and design &copy; {year} Aunty Cat, unless otherwise
          stated. Created with the help of{' '}
          <a
            href='https://www.pokeapi.co'
            target='_blank'
            rel='noreferrer nofollow'
          >
            PokéAPI
          </a>
          ,{' '}
          <a
            href='https://www.pokemondb.net'
            target='_blank'
            rel='noreferrer nofollow'
          >
            Pokémon Database
          </a>
          ,{' '}
          <a
            href='https://bulbapedia.bulbagarden.net'
            target='_blank'
            rel='noreferrer nofollow'
          >
            Bulbagarden
          </a>{' '}
          and{' '}
          <a
            href='https://github.com/itsjavi/pokemon-assets'
            target='_blank'
            rel='noreferrer nofollow'
          >
            @itsjavi/pokemon-assets
          </a>{' '}
          for data and images. <br /> Pokémon &copy; 1995–2021
          Nintendo/Creatures Inc./Game Freak Inc. TM &reg; and Pokémon character
          names are trademarks of Nintendo. The use of Pokémon intellectual
          property on this website is for fan reference. No copyright or
          trademark infringement is intended in using Pokémon content on this
          website. This website is not affiliated in any way with Nintendo,
          Pokémon or any other company.
        </p>
      </Container>
    </footer>
  )
}

export default Copyright
