import axios from 'axios'

const API_URL = 'https://pokegame-backend.vercel.app'

// Get 4 random pokemons from database
export const getPokemons = async () => {
  const info = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

  const { token } = info

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios
    .get(`${API_URL}/api/pokeData/pokeguess`, config)
    .then((res) => res.data)

  return response
}

// Function to shuffle array ('Durstenfeld shuffle')
export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

// Update coins after answering correctly
export const earnCoins = async (reward) => {
  const info = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

  const { token } = info

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios
    .put(`${API_URL}/api/user/mycoins`, reward, config)
    .then((res) => res.data)

  return response
}
