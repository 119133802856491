import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Container } from 'semantic-ui-react'
import routes from './config/routes'
import Header from './components/Header'
import NotFoundPage from './pages/NotFoundPage'
import UserState from './context/user/UserState'
import AppRoutes from './components/AppRoutes'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

const App = () => {
  return (
    <UserState>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Header />
          <main>
            <Container>
              <Switch>
                {routes.map((route) => (
                  <AppRoutes
                    key={route.path}
                    path={route.path}
                    component={route.component}
                    isPrivate={route.isPrivate}
                    exact
                  />
                ))}
                <Route path='/*' component={NotFoundPage} />
              </Switch>
            </Container>
          </main>
        </Router>
      </QueryClientProvider>
    </UserState>
  )
}

export default App
