import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import UserContext from '../context/user/userContext'
import { buyEgg } from '../actions/myEggsActions'
import Meta from '../components/Meta'
import Timer from '../components/Timer'
import GetBagItems from '../hooks/GetBagItems'
import { Button, Grid, Header, Image, Message } from 'semantic-ui-react'

const EggsPage = ({ history }) => {
  const userContext = useContext(UserContext)
  const { userInfo } = userContext

  const ASSETS_URL = 'https://assets.meaah.com/file/meaah-poke/lib'

  const [message, setMessage] = useState(null)

  const queryClient = useQueryClient()
  const addEgg = useMutation(buyEgg)

  const { isLoading, data } = GetBagItems()

  const toHatchEggPage = new Audio(`${ASSETS_URL}/sounds/whooshSound.mp3`)

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    }
  }, [history, userInfo])

  const handleBtnClick = (e) => {
    for (let obj of data.incubators)
      if (obj._id === e.target.value && obj.hasEgg === false) {
        if (data.coins < 50) {
          setMessage("You don't have enough coins to buy an egg!")
        } else {
          addEgg.mutate(
            { id: e.target.value },
            {
              onSettled: () => {
                queryClient.invalidateQueries('bag')
              },
            }
          )
        }
      }
  }

  return (
    <Grid container centered id='eggs-page-grid-container'>
      <Meta title='Meaah | Eggs' description='Eggs and incubators' />
      <Image
        src={`${ASSETS_URL}/images/eggs/eggs-title.png`}
        className='eggs-title-image'
      />
      <Grid.Row>
        <Header as='h2' color='orange'>
          <Image
            id='header-coin'
            src={`${ASSETS_URL}/images/header/coin.png`}
          />{' '}
          50 =
          <Image id='header-egg' src={`${ASSETS_URL}/images/eggs/egg.png`} />
        </Header>
      </Grid.Row>

      {isLoading ? (
        <Grid.Row>
          <Header as='h3'>Loading...</Header>
          <Image src={`${ASSETS_URL}/images/pokemons/walkingPikachu.gif`} />
        </Grid.Row>
      ) : (
        <>
          {message && <Message attached negative content={message} />}

          <Grid.Row id='eggs-row' columns={5}>
            {data.incubators.map((data, index) => (
              <Grid.Column
                key={index}
                textAlign='center'
                className='incubator-container'
              >
                <Header as='h3' color='violet'>
                  Incubator {index + 1}
                </Header>
                <Image
                  className='incubator-img'
                  src={
                    !data.hasEgg
                      ? `${ASSETS_URL}/images/eggs/incubator.png`
                      : data.readyHatch
                      ? `${ASSETS_URL}/images/eggs/incubator-cracked-egg.png`
                      : `${ASSETS_URL}/images/eggs/incubator-egg.png`
                  }
                />
                {data.hasEgg && data.readyHatch ? (
                  <Link to={`/eggs/${data._id}`}>
                    <Button
                      onClick={() => toHatchEggPage.play()}
                      fluid
                      compact
                      color='green'
                    >
                      Hatch!
                    </Button>
                  </Link>
                ) : (
                  <Button
                    fluid
                    compact
                    value={data._id}
                    color={!data.hasEgg ? 'red' : 'yellow'}
                    onClick={handleBtnClick}
                  >
                    {!data.hasEgg ? 'Buy egg!' : 'Incubating...'}
                  </Button>
                )}

                {data.hasEgg && !data.readyHatch && <Timer eggData={data} />}
              </Grid.Column>
            ))}
          </Grid.Row>
        </>
      )}
    </Grid>
  )
}

export default EggsPage
