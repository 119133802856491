import axios from 'axios'
import { fetchPokeNums } from './myPokemonsActions'
import { shuffleArray } from './pokeguessActions'

const API_URL = 'https://pokegame-backend.vercel.app'

// Buy egg
export const buyEgg = async (id) => {
  const info = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

  const { token } = info

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios
    .put(`${API_URL}/api/user/myeggs/buyegg`, id, config)
    .then((res) => res.data)

  return response
}

// Update egg status to ready
export const updateEggStatus = async (id) => {
  const info = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

  const { token } = info

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios
    .put(`${API_URL}/api/user/myeggs/readyegg`, id, config)
    .then((res) => res.data)

  return response
}

// Get random, unique pokemon
const random = (min, max) => Math.floor(Math.random() * (max - min)) + min

const getRandomUnique = (blacklist, min, max) => {
  let num
  if (!Array.isArray(blacklist)) {
    num = random(min, max)
  }
  do {
    num = random(min, max)
  } while ((blacklist || []).includes(num) || num < 0)

  return num
}

export const getNewPokemon = async () => {
  const pokedex = await fetchPokeNums()
  const excludeList = pokedex.pokemons
  let selectedNum

  selectedNum = getRandomUnique(excludeList, 1, 896)

  return selectedNum
}

// Generate Maths questions for EggsHatchPage
export const getMathsQuiz = () => {
  let addNum1 = random(1, 20)
  let addNum2 = random(1, 20)
  let addAns = addNum1 + addNum2
  let subtractNum1 = random(10, 20)
  let subtractNum2 = random(1, 10)
  let subtractAns = subtractNum1 - subtractNum2
  let multiNum1 = random(1, 11)
  let multiNum2 = random(1, 11)
  let multiAns = multiNum1 * multiNum2
  let addChoices = [addAns]
  let subChoices = [subtractAns]
  let multiChoices = [multiAns]

  for (let i = 0; addChoices.length < 3; i++) {
    let resA = getRandomUnique(addChoices, addAns - 3, addAns + 5)
    addChoices.push(resA)
  }

  for (let j = 0; subChoices.length < 3; j++) {
    let resB = getRandomUnique(subChoices, subtractAns - 3, subtractAns + 5)
    subChoices.push(resB)
  }

  for (let k = 0; multiChoices.length < 3; k++) {
    let resC = getRandomUnique(multiChoices, multiAns - 3, multiAns + 5)
    multiChoices.push(resC)
  }

  let quizObj = {}

  quizObj = {
    add: {
      qn: `What is ${addNum1} + ${addNum2}?`,
      ans: addAns,
      choices: shuffleArray(addChoices),
    },
    subtract: {
      qn: `What is ${subtractNum1} - ${subtractNum2}?`,
      ans: subtractAns,
      choices: shuffleArray(subChoices),
    },
    multi: {
      qn: `What is ${multiNum1} x ${multiNum2}?`,
      ans: multiAns,
      choices: shuffleArray(multiChoices),
    },
  }

  return quizObj
}

export const getPokeName = async (num) => {
  const info = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

  const { token } = info

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios
    .post(`${API_URL}/api/pokeData/pokename`, num, config)
    .then((res) => res.data)

  return response
}

// Hatch egg
export const hatchEggToPokemon = async (id, num) => {
  const info = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

  const { token } = info

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios
    .put(`${API_URL}/api/user/myeggs/${id}`, num, config)
    .then((res) => res.data)

  return response
}
