import axios from 'axios'

const API_URL = 'https://pokegame-backend.vercel.app'

// Get random word from database
export const getWord = async () => {
  const info = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

  const { token } = info

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios
    .get(`${API_URL}/api/wordguessdata`, config)
    .then((res) => res.data)

  return response.randomWord[0]
}

// Check guessed letters against correct letters
export const matchLetters = (correct, guessed) => {
  return correct.every((letter) => guessed.includes(letter))
}

// Text-to-speech using Web Speech API

export const getVoices = () => {
  let synth = window.speechSynthesis
  let voices
  let voice
  let regex = /^en-au?$/i
  voices = synth
    .getVoices()
    .filter((voice) => regex.test(voice.lang))
    .map((voice) => ({
      voice: voice,
    }))
  voice =
    voices && voices.length > 0
      ? voices[Math.floor(Math.random() * voices.length)]
      : null

  return voice
}
