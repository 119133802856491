// import React from 'react'
import LoginPage from '../pages/LoginPage'
import RegisterPage from '../pages/RegisterPage'
import ProfilePage from '../pages/ProfilePage'
import HomePage from '../pages/HomePage'
import PokemonsPage from '../pages/PokemonsPage'
import EggsPage from '../pages/EggsPage'
import EggsHatchPage from '../pages/EggsHatchPage'
import GuessPokemonPage from '../pages/GuessPokemonPage'
import GuessWordPage from '../pages/GuessWordPage'

const routes = [
  {
    path: '/login',
    component: LoginPage,
    isPrivate: false,
  },
  {
    path: '/register',
    component: RegisterPage,
    isPrivate: false,
  },
  {
    path: '/profile',
    component: ProfilePage,
    isPrivate: true,
  },
  {
    path: '/pokeguess',
    component: GuessPokemonPage,
    isPrivate: true,
  },
  {
    path: '/wordguess',
    component: GuessWordPage,
    isPrivate: true,
  },
  {
    path: '/pokemons',
    component: PokemonsPage,
    isPrivate: true,
  },
  {
    path: '/eggs',
    component: EggsPage,
    isPrivate: true,
  },
  {
    path: '/eggs/:id',
    component: EggsHatchPage,
    isPrivate: true,
  },
  {
    path: '/',
    component: HomePage,
    isPrivate: false,
  },
]

export default routes
