import React, { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import UserContext from '../context/user/userContext'
import { Grid, Header, Image, Label, Message } from 'semantic-ui-react'
import Meta from '../components/Meta'
import {
  fetchMyPokemons,
  colors,
  type_btn_colors,
} from '../actions/myPokemonsActions'

const PokemonsPage = ({ history }) => {
  const userContext = useContext(UserContext)
  const { userInfo } = userContext

  const ASSETS_URL = 'https://assets.meaah.com/file/meaah-poke/lib'

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    }
  }, [history, userInfo])

  const { isLoading, data } = useQuery('pokemons', fetchMyPokemons)

  return (
    <Grid container centered id='pokemons-page-grid-container'>
      <Meta title='Meaah | My Pokemons' description='My pokemons' />
      <Image
        src={`${ASSETS_URL}/images/pokemons/mypokemons-title.png`}
        className='mypokemons-title-image'
      />

      <Grid.Row>
        {isLoading ? (
          <>
            <Header as='h3'>Loading...</Header>
            <Image src={`${ASSETS_URL}/images/pokemons/walkingPikachu.gif`} />
          </>
        ) : data.length < 1 ? (
          <Message negative>
            <Message.Header>Oh no, you have ZERO Pokémon!</Message.Header>
            <p>
              Hatch eggs into Pokémons! Play a game and earn some coins to buy
              eggs.
            </p>

            <Image src={`${ASSETS_URL}/images/notfound/psyduck.svg`} />
          </Message>
        ) : (
          <>
            <Grid.Column width={16} textAlign='center'>
              <Header id='total-pokemons' as='h2' color='violet'>
                Total Pokémons: {data.length}
              </Header>
            </Grid.Column>

            {data.map((data, i) => (
              <Grid.Row
                key={i}
                className='pokecard-main-container'
                style={{ backgroundColor: colors[data.types[0]] }}
              >
                <div className='pokecard-img-container'>
                  <Image
                    centered
                    className='pokecard-img'
                    alt={data.name}
                    src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/${data.num}.png`}
                  />
                </div>
                <div className='pokecard-info'>
                  <span className='number'>
                    {'#' + data.num.toString().padStart(3, '0')}
                  </span>
                  <h3 className='name'>{data.name}</h3>
                  {data.types.map((types, index) => (
                    <Label
                      className='types'
                      key={index}
                      style={{
                        backgroundColor: type_btn_colors[data.types[index]],
                      }}
                    >
                      {data.types[index]}
                    </Label>
                  ))}
                </div>
              </Grid.Row>
            ))}
          </>
        )}
      </Grid.Row>
    </Grid>
  )
}

export default PokemonsPage
