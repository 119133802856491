import React, { useState, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { updateEggStatus } from '../actions/myEggsActions'
import Countdown, { zeroPad } from 'react-countdown'

const Timer = (props) => {
  const [complete, setComplete] = useState(false)
  const { _id, hatchAt } = props.eggData
  const queryClient = useQueryClient()
  const updateEgg = useMutation(updateEggStatus)

  useEffect(() => {
    if (complete) {
      const update = () => {
        updateEgg.mutate(
          { id: _id },
          {
            onSettled: () => {
              queryClient.invalidateQueries('bag')
            },
          }
        )
      }
      update()
    }
    // eslint-disable-next-line
  }, [complete])

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      setTimeout(() => {
        setComplete(true)
      }, 0)
      return <span></span>
    } else {
      return (
        <span>
          Time left:{' '}
          <span style={{ color: 'red' }}>
            {zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        </span>
      )
    }
  }

  return <Countdown date={hatchAt} renderer={renderer} />
}

export default Timer
