import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react'
import UserContext from '../context/user/userContext'
import Meta from '../components/Meta'

const RegisterPage = ({ history }) => {
  const userContext = useContext(UserContext)
  const { clearError, loading, error, userInfo, register } = userContext

  const ASSETS_URL = 'https://assets.meaah.com/file/meaah-poke/lib'

  const [nickname, setNickname] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const sound = new Audio(`${ASSETS_URL}/sounds/registerSound.mp3`)

  useEffect(() => {
    clearError()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (userInfo) {
      sound.play()
      history.push('/')
    }
    // eslint-disable-next-line
  }, [history, userInfo])

  const submitHandler = (e) => {
    e.preventDefault()
    setMessage(null)
    clearError()
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else if (nickname.length < 1) {
      setMessage('Nickname is required')
    } else if (username.length < 5) {
      setMessage('Username must be at least 5 characters long')
    } else if (password.length < 5) {
      setMessage('Password must be at least 5 characters long')
    } else {
      register(nickname, username, password)
    }
  }

  return (
    <Grid id='register-container' textAlign='center' verticalAlign='middle'>
      <Meta
        title='Meaah | Register'
        description='Register for new Meaah account'
      />
      <Grid.Column id='register-form-container'>
        <Header as='h2' textAlign='center' id='register-header'>
          <Image
            src={`${ASSETS_URL}/images/register/pikachu-face.png`}
            id='register-image'
          />
          Register new account
        </Header>
        {message && <Message attached negative content={message} />}
        {error && <Message attached negative content={error} />}
        <Form size='large'>
          <Segment textAlign='left'>
            <Form.Input
              fluid
              autoComplete='off'
              required
              label='Nickname'
              icon='user'
              iconPosition='left'
              placeholder='Your nickname for profile display'
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
            />
            <Form.Input
              fluid
              autoComplete='off'
              required
              label='Username'
              icon='user'
              iconPosition='left'
              placeholder='Account username (at least 5 characters long)'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Form.Input
              fluid
              autoComplete='off'
              required
              label='Password'
              icon='lock'
              iconPosition='left'
              placeholder='Password (at least 5 characters long)'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Input
              fluid
              autoComplete='off'
              required
              label='Confirm password'
              icon='lock'
              iconPosition='left'
              placeholder='This must match the password above'
              type='password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              onClick={submitHandler}
              disabled={loading}
              color='green'
              fluid
              size='large'
            >
              Register
            </Button>
          </Segment>
        </Form>
        <Message attached='bottom' color='yellow'>
          Already have an account? <Link to='/login'> Sign in!</Link>
        </Message>
      </Grid.Column>
    </Grid>
  )
}

export default RegisterPage
