import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Particles from 'react-tsparticles'
import UserContext from '../context/user/userContext'
import {
  getNewPokemon,
  getMathsQuiz,
  getPokeName,
  hatchEggToPokemon,
} from '../actions/myEggsActions'
import Meta from '../components/Meta'
import { Button, Grid, Header, Message, Progress } from 'semantic-ui-react'

const EggsHatchPage = ({ history, match }) => {
  const userContext = useContext(UserContext)
  const { userInfo } = userContext

  const id = match.params.id

  const ASSETS_URL = 'https://assets.meaah.com/file/meaah-poke/lib'

  const [reset, setReset] = useState(true)
  const [quizQns, setQuizQns] = useState([])
  const [quizAns, setQuizAns] = useState([])
  const [quizChoicesAdd, setQuizChoicesAdd] = useState([])
  const [quizChoicesSub, setQuizChoicesSub] = useState([])
  const [quizChoicesMulti, setQuizChoicesMulti] = useState([])
  const [firstCorrect, setFirstCorrect] = useState(false)
  const [secondCorrect, setSecondCorrect] = useState(false)
  const [finalCorrect, setFinalCorrect] = useState(false)
  const [pokeNum, setPokeNum] = useState('')
  const [pokeName, setPokeName] = useState('')
  const [imageLoaded, setImageLoaded] = useState(false)
  const [endGame, setEndGame] = useState(false)
  const [inactiveBtn, setInactiveBtn] = useState(false)

  const wrongSound = new Audio(`${ASSETS_URL}/sounds/negativeSound.mp3`)
  const correctSound = new Audio(`${ASSETS_URL}/sounds/goodSound.mp3`)
  const hatchSound = new Audio(`${ASSETS_URL}/sounds/energySound.mp3`)
  const tada = new Audio(`${ASSETS_URL}/sounds/tadaSound.mp3`)

  const startQuiz = async () => {
    setFirstCorrect(false)
    setSecondCorrect(false)
    setFinalCorrect(false)
    setInactiveBtn(false)
    setEndGame(false)
    setPokeNum('')
    setPokeName('')
    const data = await getMathsQuiz()
    setQuizQns([data.add.qn, data.subtract.qn, data.multi.qn])
    setQuizAns([data.add.ans, data.subtract.ans, data.multi.ans])
    setQuizChoicesAdd(data.add.choices)
    setQuizChoicesSub(data.subtract.choices)
    setQuizChoicesMulti(data.multi.choices)
  }

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else if (reset) {
      setImageLoaded(false)
      startQuiz()
      setReset(false)
    }
    // eslint-disable-next-line
  }, [history, userInfo, reset])

  const mouseDown = (e) => e.preventDefault()

  const handleAnsSubmit = async (e) => {
    let answer = +e.target.value
    if (!inactiveBtn) {
      if (!firstCorrect && answer === quizAns[0]) {
        setFirstCorrect(true)
        correctSound.play()
        e.target.blur()
      } else if (firstCorrect && !secondCorrect && answer === quizAns[1]) {
        setSecondCorrect(true)
        correctSound.play()
        e.target.blur()
      } else if (firstCorrect && secondCorrect && answer === quizAns[2]) {
        setFinalCorrect(true)
        setInactiveBtn(true)
        let res = await getNewPokemon()
        setPokeNum(res)
        hatchSound.play()
      } else {
        setEndGame(true)
        setInactiveBtn(true)
        wrongSound.play()
      }
    }
  }

  const handlePokeError = async () => {
    let res = await getNewPokemon()
    setPokeNum(res)
  }

  const handlePokeLoad = async () => {
    let name = await getPokeName({ num: pokeNum })
    setPokeName(name + '!')
    setImageLoaded(true)
    tada.play()
    hatchEggToPokemon(id, { pokeNum: pokeNum })
  }

  return (
    <Grid container textAlign='center' id='eggs-hatch-main-container'>
      <Meta
        title='Meaah | Hatch'
        description='Hatch egg into Pokemon'
        color='black'
      />
      <Grid.Row id='eggs-hatch-container'>
        {finalCorrect && pokeNum !== '' ? (
          <>
            {!imageLoaded && (
              <img
                className='poke-loader'
                src={`${ASSETS_URL}/images/eggshatch/loader.gif`}
                alt='loader'
              />
            )}
            <img
              style={{ display: !imageLoaded ? 'none' : 'inline' }}
              className='poke-img'
              alt='hatched pokemon'
              src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/${pokeNum}.png`}
              onError={handlePokeError}
              onLoad={handlePokeLoad}
            />
          </>
        ) : (
          <img
            id='crackedegg-img'
            className={
              secondCorrect && !finalCorrect
                ? 'crackedegg-hatch'
                : firstCorrect
                ? 'crackedegg-shake'
                : ''
            }
            src={`${ASSETS_URL}/images/eggshatch/crackedegg.png`}
            alt='cracked egg'
          />
        )}
        {finalCorrect && (
          <Grid.Column width={16}>
            <Header id='hatchegg-pokename-title' color='yellow' as='h3'>
              {`It's... ${pokeName}`}
            </Header>
            <Link to='/pokemons'>
              <Button className='end-btn-1' size='tiny' color='yellow' inverted>
                Go to Pokémons
              </Button>
            </Link>
            <Link to='/eggs'>
              <Button className='end-btn-2' size='tiny' color='yellow' inverted>
                Return to Eggs
              </Button>
            </Link>
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row id='eggs-hatch-quiz-container'>
        <Grid.Column width={10}>
          <Header id='hatchegg-header' inverted as='h2'>
            {finalCorrect
              ? ''
              : 'Answer correctly 3 times in a row to hatch the egg!'}
          </Header>

          <Progress
            id='progress-bar'
            value={
              finalCorrect ? '3' : secondCorrect ? '2' : firstCorrect ? '1' : 0
            }
            total='3'
            progress='ratio'
            inverted
            indicating
          >
            <span style={{ fontSize: 16, color: 'white' }}>
              {finalCorrect
                ? 'Well done, you did it!'
                : secondCorrect
                ? 'Just one more...'
                : firstCorrect
                ? 'Great start!'
                : `Good luck, ${userInfo.nickname}!`}
            </span>
          </Progress>
        </Grid.Column>
        <Grid.Column id='quiz-box' width={14}>
          <Grid.Row id='quiz-question'>
            <Header inverted color='teal' as='h3'>
              {!firstCorrect && !secondCorrect
                ? `First Question: ${quizQns[0]}`
                : firstCorrect && !secondCorrect && !finalCorrect
                ? `Second Question: ${quizQns[1]}`
                : `Final Question: ${quizQns[2]}`}
            </Header>
          </Grid.Row>
          <Grid.Row>
            <Button.Group widths='3'>
              {!firstCorrect && !secondCorrect && !finalCorrect && (
                <>
                  <Button
                    onMouseDown={mouseDown}
                    onClick={handleAnsSubmit}
                    value={quizChoicesAdd[0]}
                    inverted
                    color='orange'
                  >
                    {quizChoicesAdd[0]}
                  </Button>
                  <Button
                    onMouseDown={mouseDown}
                    onClick={handleAnsSubmit}
                    value={quizChoicesAdd[1]}
                    inverted
                    color='green'
                  >
                    {quizChoicesAdd[1]}
                  </Button>
                  <Button
                    onMouseDown={mouseDown}
                    onClick={handleAnsSubmit}
                    value={quizChoicesAdd[2]}
                    inverted
                    color='blue'
                  >
                    {quizChoicesAdd[2]}
                  </Button>
                </>
              )}
              {firstCorrect && !secondCorrect && !finalCorrect && (
                <>
                  <Button
                    onMouseDown={mouseDown}
                    onClick={handleAnsSubmit}
                    value={quizChoicesSub[0]}
                    inverted
                    color='orange'
                  >
                    {quizChoicesSub[0]}
                  </Button>
                  <Button
                    onMouseDown={mouseDown}
                    onClick={handleAnsSubmit}
                    value={quizChoicesSub[1]}
                    inverted
                    color='green'
                  >
                    {quizChoicesSub[1]}
                  </Button>
                  <Button
                    onMouseDown={mouseDown}
                    onClick={handleAnsSubmit}
                    value={quizChoicesSub[2]}
                    inverted
                    color='blue'
                  >
                    {quizChoicesSub[2]}
                  </Button>
                </>
              )}
              {firstCorrect && secondCorrect && (
                <>
                  <Button
                    disabled={inactiveBtn ? true : false}
                    onMouseDown={mouseDown}
                    onClick={handleAnsSubmit}
                    value={quizChoicesMulti[0]}
                    inverted
                    color='orange'
                  >
                    {quizChoicesMulti[0]}
                  </Button>
                  <Button
                    disabled={inactiveBtn ? true : false}
                    onMouseDown={mouseDown}
                    onClick={handleAnsSubmit}
                    value={quizChoicesMulti[1]}
                    inverted
                    color='green'
                  >
                    {quizChoicesMulti[1]}
                  </Button>
                  <Button
                    disabled={inactiveBtn ? true : false}
                    onMouseDown={mouseDown}
                    onClick={handleAnsSubmit}
                    value={quizChoicesMulti[2]}
                    inverted
                    color='blue'
                  >
                    {quizChoicesMulti[2]}
                  </Button>
                </>
              )}
            </Button.Group>
          </Grid.Row>
          {endGame && (
            <Message size='small' compact negative>
              Incorrect! The answer is{' '}
              {!firstCorrect
                ? quizAns[0]
                : firstCorrect && !secondCorrect
                ? quizAns[1]
                : quizAns[2]}
              . You have to start from the first question again. {''}
              <Button
                onClick={() => setReset(true)}
                style={{ marginLeft: 10 }}
                color='yellow'
                size='mini'
                icon='repeat'
              />
            </Message>
          )}
        </Grid.Column>
      </Grid.Row>

      <Particles
        id='tsparticles'
        options={{
          fpsLimit: 30,
          particles: {
            color: {
              value: '#fc6711',
            },
            links: {
              color: '#fc6711',
              distance: 110,
              enable: true,
              opacity: 0.8,
              width: 0.5,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: 'none',
              enable: true,
              outMode: 'bounce',
              random: false,
              speed: 3,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                value_area: 1000,
              },
              value: 70,
            },
            opacity: {
              value: 0.6,
            },
            shape: {
              type: 'circle',
            },
            size: {
              random: true,
              value: 4,
            },
          },
          detectRetina: true,
        }}
      />
    </Grid>
  )
}

export default EggsHatchPage
