import React, { useContext, useState, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import UserContext from '../context/user/userContext'
import Confetti from 'react-dom-confetti'
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Segment,
} from 'semantic-ui-react'
import Meta from '../components/Meta'
import {
  getPokemons,
  shuffleArray,
  earnCoins,
} from '../actions/pokeguessActions'

const GuessWordPage = ({ history }) => {
  const userContext = useContext(UserContext)
  const { userInfo } = userContext

  const queryClient = useQueryClient()

  const ASSETS_URL = 'https://assets.meaah.com/file/meaah-poke/lib'

  const [rightChoice, setRightChoice] = useState('')
  const [rightNum, setRightNum] = useState('')
  const [choices, setChoices] = useState([])
  const [correctAns, setCorrectAns] = useState(false)
  const [answer, setAnswer] = useState('')
  const [submitAns, setSubmitAns] = useState(false)
  const [clickedAns, setClickedAns] = useState(false)
  const [reset, setReset] = useState(true)
  const [shadowMode, setShadowMode] = useState(true)
  const [revealPokemon, setRevealPokemon] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)

  const winSound = new Audio(`${ASSETS_URL}/sounds/winnerSound.mp3`)
  const loseSound = new Audio(`${ASSETS_URL}/sounds/wrongAns.mp3`)

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else if (reset) {
      newGame()
      setReset(false)
    }
    // eslint-disable-next-line
  }, [history, userInfo, reset])

  const newGame = async () => {
    const data = await getPokemons()
    let shuffledAnswerChoices = shuffleArray(data.answerChoices)
    setChoices(shuffledAnswerChoices)
    setRightChoice(data.chosenPokemon)
    setRightNum(data.chosenNum)
    setSubmitAns(false)
    setCorrectAns(false)
    setClickedAns(false)
    setImageLoaded(false)
    setAnswer('')
    if (shadowMode) {
      setRevealPokemon(false)
    }
  }

  const updateCoins = useMutation(earnCoins)

  const handleSubmitAns = () => {
    setRevealPokemon(true)
    setSubmitAns(true)
    if (answer === rightChoice) {
      winSound.play()
      setCorrectAns(true)
      let reward = shadowMode ? 10 : 5
      updateCoins.mutate(
        { coins: reward },
        {
          onSettled: () => {
            queryClient.invalidateQueries('bag')
          },
        }
      )
    } else {
      loseSound.play()
    }
  }

  const imageRender = () => {
    if (imageLoaded) {
      return revealPokemon || !shadowMode ? '' : 'poke-shadow'
    }
    return 'hidden'
  }

  return (
    <Grid container centered id='pokeguess-page-grid-container'>
      <Meta
        title='Meaah | Guess Pokemon'
        description='Guess the name of the pokemon in the image'
      />
      <Grid.Column width={4}>
        <Image
          id='pokeguess-left-column-img'
          src={`${ASSETS_URL}/images/pokeguess/prof_willow.png`}
        />
      </Grid.Column>
      <Grid.Column width={8} style={{ paddingTop: 5 }}>
        <Image
          src={`${ASSETS_URL}/images/pokeguess/pokeguess-title.png`}
          className='pokeguess-title-image'
        />
        <Segment
          style={{ left: 80 }}
          circular
          color='olive'
          inverted
          id='pokeguess-poke-container'
        >
          <Image
            id='pokeguess-loader'
            className={imageLoaded ? 'hidden' : ''}
            src={`${ASSETS_URL}/images/pokeball_loader.gif`}
          />

          <Image
            centered
            id='pokeguess-pokemon-img'
            className={imageRender()}
            src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/other/official-artwork/${rightNum}.png`}
            onError={() => setReset(true)}
            onLoad={() => setImageLoaded(true)}
          />

          <Confetti active={correctAns} />
        </Segment>

        <Segment id='poke-guess-answer-form'>
          <Form>
            <Form.Field>
              <Header as='h3'>Choose your answer:</Header>
            </Form.Field>
            {imageLoaded && (
              <Grid>
                <Grid.Column width={8}>
                  {choices.map((choice, i) => (
                    <Form.Field key={'pokeguessAns-' + i}>
                      <Checkbox
                        key={'pokeguess-' + choice}
                        label={choice}
                        name='answerRadioGroup'
                        value={choice}
                        checked={choice === answer}
                        onClick={(e, { value }) => {
                          if (!submitAns) {
                            setAnswer(value)
                            setClickedAns(true)
                          }
                        }}
                      />
                    </Form.Field>
                  ))}
                </Grid.Column>
                <Grid.Column textAlign='center' width={8}>
                  <Header color='teal' as='h2'>
                    {answer}
                  </Header>
                  {clickedAns && (
                    <Button
                      onClick={handleSubmitAns}
                      color='green'
                      disabled={submitAns}
                    >
                      Confirm
                    </Button>
                  )}
                </Grid.Column>
              </Grid>
            )}
          </Form>
        </Segment>
      </Grid.Column>
      <Grid.Column id='column-right' width={4}>
        <div>
          <Header as='h3'>Game Mode:</Header>
          <Button.Group>
            <Button
              color={!shadowMode ? 'orange' : 'grey'}
              onClick={() => {
                if (!submitAns) {
                  setShadowMode(false)
                  setRevealPokemon(true)
                }
              }}
            >
              Picture
            </Button>
            <Button.Or />
            <Button
              color={shadowMode ? 'orange' : 'grey'}
              onClick={() => {
                if (revealPokemon && !submitAns) {
                  setReset(true)
                  setShadowMode(true)
                  setRevealPokemon(false)
                } else if (!submitAns && !clickedAns) {
                  setShadowMode(true)
                  setRevealPokemon(false)
                }
              }}
            >
              Shadow
            </Button>
          </Button.Group>
        </div>
        <Header id='reward-row' as='h3'>
          Reward:
          <Label image id='pokeguees-reward-label'>
            <img src={`${ASSETS_URL}/images/header/coin.png`} alt='coin logo' />
            <Label.Detail id='pokeguees-reward-details'>
              {shadowMode ? '10' : '5'}
            </Label.Detail>
          </Label>
        </Header>
        {!submitAns ? (
          ''
        ) : correctAns ? (
          <>
            <Image
              src={`${ASSETS_URL}/images/pokeguess/correct-ans.png`}
              size='small'
              id='correct-ans-logo'
            />
            <Segment>
              <h3>
                CORRECT! <br />
                You get {shadowMode ? '10' : '5'} coins.
              </h3>
            </Segment>
          </>
        ) : (
          <>
            <Image
              size='small'
              src={`${ASSETS_URL}/images/pokeguess/wrong-ans.png`}
              id='wrong-ans-logo'
            />
            <Segment>
              <h3>
                WRONG! <br />
                The correct answer is{' '}
                <span style={{ color: 'red' }}>{rightChoice}</span>.
              </h3>
            </Segment>
          </>
        )}
        <Button color='orange' inverted onClick={() => setReset(true)}>
          <Icon name='repeat' />
          Guess another
        </Button>
      </Grid.Column>
    </Grid>
  )
}

export default GuessWordPage
