import {
  CLEAR_ERROR,
  CLEAR_UPDATE_SUCCESS_MSG,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from '../types'

const UserReducer = (state, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
      }
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case LOGOUT:
      return {}
    case REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
      }
    case REGISTER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        updateSuccessMsg: 'Profile updated!',
      }
    case UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case CLEAR_UPDATE_SUCCESS_MSG:
      return {
        ...state,
        updateSuccessMsg: '',
      }
    case CLEAR_ERROR:
      return {
        ...state,
        error: '',
      }
    default:
      return state
  }
}

export default UserReducer
