import React from 'react'
import { Label, Image } from 'semantic-ui-react'
import GetBagItems from '../hooks/GetBagItems'

const Score = () => {
  const { data } = GetBagItems()

  const ASSETS_URL = 'https://assets.meaah.com/file/meaah-poke/lib'

  return (
    <>
      <Label id='header-score' size='large' color='red' image>
        <Image
          id='header-score-logo'
          src={`${ASSETS_URL}/images/header/coin.png`}
          alt='coin icon'
        />
        {''}
        {data?.coins}
      </Label>
    </>
  )
}

export default Score
