import React from 'react'
import { Grid, Image } from 'semantic-ui-react'

const LetterKeys = (props) => {
  const ASSETS_URL = 'https://assets.meaah.com/file/meaah-poke/lib'

  const rowOneLetters = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
  ]

  const rowTwoLetters = [
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ]

  const click = (e) => {
    props.parentMethod(e)
  }

  return (
    <Grid.Row id='letterKeys-container' stretched verticalAlign='middle'>
      <Grid.Column id='keyboard' width={14}>
        <Grid.Row className='letter-row'>
          {rowOneLetters.map((letter) => (
            <button
              onClick={click}
              key={`rowOne-${letter}`}
              value={letter}
              className='letterKeys-btn'
            >
              {letter}
            </button>
          ))}
        </Grid.Row>
        <Grid.Row className='letter-row'>
          {rowTwoLetters.map((letter) => (
            <button
              onClick={click}
              key={`rowTwo-${letter}`}
              value={letter}
              className='letterKeys-btn'
            >
              {letter}
            </button>
          ))}
        </Grid.Row>
      </Grid.Column>
      <Grid.Column width={2} style={{ padding: 0 }}>
        <Image
          id='keyboard-img'
          src={`${ASSETS_URL}/images/wordguess/cluelessbulbasaur.gif`}
          alt='Puzzled bulbasaur'
          floated='left'
        />
      </Grid.Column>
    </Grid.Row>
  )
}

export default LetterKeys
