import React from 'react'
import { Grid, Header, Image } from 'semantic-ui-react'
import Meta from '../components/Meta'

const NotFoundPage = () => {
  const ASSETS_URL = 'https://assets.meaah.com/file/meaah-poke/lib'

  return (
    <Grid
      textAlign='center'
      verticalAlign='middle'
      style={{ height: '70vh', paddingTop: 20 }}
    >
      <Meta title='Page Not Found' description='Page does not exist' />
      <Grid.Column>
        <Header as='h2'>
          Page Not Found
          <Image src={`${ASSETS_URL}/images/notfound/psyduck.svg`} />
        </Header>
        <p>The page you are looking for does not exist...</p>
      </Grid.Column>
    </Grid>
  )
}

export default NotFoundPage
