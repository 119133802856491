import axios from 'axios'

const API_URL = 'https://pokegame-backend.vercel.app'

export const fetchPokeNums = async () => {
  const info = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

  const { token } = info

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios
    .get(`${API_URL}/api/user/mypokedex`, config)
    .then((res) => res.data)

  return response
}

export const fetchMyPokemons = async () => {
  const info = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

  const { token } = info

  const pokedex = await fetchPokeNums()
  let numbers = pokedex.pokemons
  let data = { num: numbers }

  const config2 = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios
    .post(`${API_URL}/api/pokeData/mypokemons`, data, config2)
    .then((res) => res.data)

  return response
}

export const type_btn_colors = {
  Fire: '#F0802F',
  Grass: '#78C850',
  Electric: '#F8D02F',
  Water: '#688FF0',
  Ground: '#E0C068',
  Rock: '#B8A038',
  Fairy: '#EE99AC',
  Poison: '#A040A1',
  Bug: '#A8B820',
  Dragon: '#885CF9',
  Psychic: '#F85888',
  Flying: '#A890F0',
  Fighting: '#C03028',
  Normal: '#A8A978',
  Steel: '#B8B8D0',
  Dark: '#705848',
  Ghost: '#705899',
  Ice: '#98D8D8',
}

export const colors = {
  Fire: '#f6b688',
  Grass: '#b4e09d',
  Electric: '#fae383',
  Water: '#9AC1FF',
  Ground: '#fede86',
  Rock: '#e0c860',
  Fairy: '#ffc1d4',
  Poison: '#d272d3',
  Bug: '#d0e048',
  Dragon: '#ba8eff',
  Psychic: '#ff8aba',
  Flying: '#d0b8ff',
  Fighting: '#f2625a',
  Normal: '#dadbaa',
  Steel: '#d6d6ee',
  Dark: '#a28a7a',
  Ghost: '#a28acb',
  Ice: 'c0ffff',
}
